import * as React from "react";
import { OfferType } from "../../../../types/types";
import styled from "styled-components";
import Section from "../../../../components/Section";
import { Box, Button, theme, Typography } from "@anyfin/ui";
import CopyContainer from "../../../../components/CopyContainer";
import OfferProviderCard from "../../components/OfferProviderCard";
import {
  OfferDetailsModal,
  OfferRateAssumptionLink
} from "./OfferDetailsModals";
import { useTranslation } from "react-i18next";
import { CountryCode } from "@anyfin/number-formatter/dist/cjs/_commons";
import { COUNTRY_CODES } from "../../../../utils/countries";
import needHelp from "../../../../utils/needHelp";
import { useMemo } from "react";

const OfferDetailsSection = styled(Section)``;

interface OfferListProps {
  offers: Array<OfferType>;
  demoProvider?: string;
  country: CountryCode;
}

const OfferList = ({ offers, demoProvider, country }: OfferListProps) => {
  const { t } = useTranslation("offer");

  const hasBlanco = useMemo(() => {
    return offers?.some(
      offer => offer.externalStatement?.loanType === "blanco"
    );
  }, [offers]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  return (
    <OfferDetailsSection>
      <CopyContainer marginLeft={0.5} marginRight={0.5}>
        <Typography.H4
          style={{ marginBottom: "1rem", whiteSpace: "break-spaces" }}
        >
          {t("offer_list.title_one")}
        </Typography.H4>
        <Typography size={1} style={{ marginBottom: "1rem" }}>
          {t("offer_list.description")}
        </Typography>
      </CopyContainer>
      <Box style={{ margin: "0 .5rem 1rem" }}>
        <OfferProviderCard
          offers={offers}
          demoProvider={demoProvider}
          onClick={toggleModal}
        />
      </Box>
      <CopyContainer marginLeft={0.5} marginRight={0.5}>
        <OfferRateAssumptionLink offers={offers} />
      </CopyContainer>
      {country === COUNTRY_CODES.DE && hasBlanco && (
        <CopyContainer marginLeft={0.5} marginRight={0.5} marginTop={0.5}>
          <Typography
            size={0.875}
            color={theme.colors.textLight.medium}
            style={{ marginBottom: "1rem" }}
          >
            {t("offer_list.personal_consumption_disclaimer")}
          </Typography>
        </CopyContainer>
      )}
      {country === COUNTRY_CODES.FI && (
        <Box padding={0} marginTop={1}>
          <Button
            onClick={needHelp}
            iconRight="Chat"
            rightAlignedTitle
            color="white"
            fontWeightMedium
            fontSize={1}
          >
            {t("offer_loan_detail.something_wrong")}
          </Button>
        </Box>
      )}
      <OfferDetailsModal
        offers={offers}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        demoProvider={demoProvider}
      ></OfferDetailsModal>
    </OfferDetailsSection>
  );
};

export const handleDdiAndOtto = (offers: Array<OfferType>) => {
  if (!offers)
    return {
      isDdi: false,
      isOtto: false
    };
  let isDdi = false;
  let isOtto = false;
  for (const offer of offers) {
    if (offer?.application?.isDdi) isDdi = true;
    if (offer.lender.name === "Otto") isOtto = true;
  }
  return {
    isDdi,
    isOtto
  };
};

export default OfferList;
