import { gql } from "@apollo/client";
import { withQuery } from "@apollo/client/react/hoc";
import compose from "lodash/flowRight";
import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Box, Notification, Typography } from "@anyfin/ui";
import CopyContainer from "../../components/CopyContainer";
import Section from "../../components/Section";

import getAppSE from "../../assets/get-app_se.jpg";
import getAppFI from "../../assets/get-app_fi.jpg";
import getAppDE from "../../assets/get-app_de.jpg";

const ImageContainer = styled.div`
  height: 384px;
  width: 100%;

  > img {
    max-width: 100%;
    height: auto;
  }
`;

const StyledBox = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

const countryImg = () => ({
  SE: getAppSE,
  FI: getAppFI,
  DE: getAppDE,
  NO: getAppSE
});

const HomePage = ({ history, UserQuery }) => {
  const me = UserQuery?.me;
  const { t } = useTranslation(["home", "common"]);

  useEffect(() => {
    const postLoginRedirect = sessionStorage.getItem("redirect");
    if (postLoginRedirect) {
      sessionStorage.clear("redirect");
      history.push(postLoginRedirect);
    }
  }, []);

  const goToOffers = useCallback(() => {
    window.scrollTo(0, 0);
    history.push(`offer`);
  }, [me, history]);

  const goToLoans = useCallback(() => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "loans",
      state: { loans: me.loans }
    });
  }, [me, history]);

  const goToRefund = useCallback(() => {
    history.push(`overpayment_refund_form`);
  }, [history]);

  if (!me) return null;

  const {
    offers = [],
    applications = [],
    overpaymentRefundEligibility = {},
    loans = []
  } = me;

  const transferMissingInfoTokens = applications
    .filter(
      application =>
        application.transfer &&
        application.transfer.metadata?.missingFields?.length > 0
    )
    .map(a => a.transfer?.metadata?.missingInfoToken);

  return (
    <Section marginBottom={["xxxlarge", "xxxlarge", "6x"]}>
      {offers.length > 0 && (
        <StyledBox marginTop="large" paddingLeft="medium" paddingRight="medium">
          <Notification iconRight="ArrowRight" onClick={goToOffers}>
            <Box direction="row">
              <Box marginRight="xlarge" style={{ alignSelf: "flex-start" }}>
                <span role="img" aria-label="Thummbs up emoji">
                  👍
                </span>
              </Box>
              {t("common:notification.new_offer")}
            </Box>
          </Notification>
        </StyledBox>
      )}
      {loans.length > 0 && (
        <StyledBox marginTop="large" paddingLeft="medium" paddingRight="medium">
          <Notification iconRight="ArrowRight" onClick={goToLoans}>
            <Box direction="row">
              <Box marginRight="xlarge" style={{ alignSelf: "flex-start" }}>
                <span role="img" aria-label="Thummbs up emoji">
                  👍
                </span>
              </Box>
              {t("common:notification.check_loans")}
            </Box>
          </Notification>
        </StyledBox>
      )}
      {transferMissingInfoTokens.map(missingInfoToken => (
        <StyledBox
          key={missingInfoToken}
          marginTop="large"
          paddingLeft="medium"
          paddingRight="medium"
        >
          <Notification
            iconRight="ArrowRight"
            onClick={() => {
              history.push(`t/${missingInfoToken}`);
            }}
          >
            <Box direction="row">
              <Box marginRight="xlarge" style={{ alignSelf: "flex-start" }}>
                <span role="img" aria-label="Looking glass emoji">
                  🔍
                </span>
              </Box>
              {t("info:incomplete_header")}
            </Box>
          </Notification>
        </StyledBox>
      ))}
      {overpaymentRefundEligibility?.status === "ELIGIBLE" ? (
        <StyledBox marginTop="large" paddingLeft="medium" paddingRight="medium">
          <Notification iconRight="ArrowRight" onClick={goToRefund}>
            <Box direction="row">
              <Box marginRight="xlarge" style={{ alignSelf: "flex-start" }}>
                <span role="img" aria-label="Money bag emoji">
                  💰
                </span>
              </Box>
              {t("common:notification.refund_available")}
            </Box>
          </Notification>
        </StyledBox>
      ) : null}
      <CopyContainer marginTop={2.5} marginBottom="medium">
        <Typography.H3>{t("headline")}</Typography.H3>
      </CopyContainer>
      <CopyContainer marginTop={1.25} marginBottom="medium">
        <Typography.H7>{t("body")}</Typography.H7>
      </CopyContainer>

      <ImageContainer>
        <img src={countryImg()?.[me.countryCode]} alt="Download app" />
      </ImageContainer>
    </Section>
  );
};

const UserQuery = withQuery(
  gql`
    query UserQuery {
      me {
        id
        countryCode
        firstName
        offersToken
        offers(status: "open") {
          id
        }
        applications(status: "open") {
          id
          step
          transfer {
            id
            createdAt
            status
            amount
            metadata {
              missingInfoToken
              missingFields
            }
          }
        }
        overpaymentRefundEligibility {
          status
        }
        loans(status: "active") {
          id
          contract {
            id
            publicUrl
          }
          lender {
            id
            name
          }
          balance
          originalAmount
          interestRate
        }
      }
    }
  `,
  { name: "UserQuery" }
);

export default compose(UserQuery)(HomePage);
